// @ts-check

import Vue from 'vue'
import Debug from '@/debugger'
import VueInstance from '@/main'
import Scope from './scope.js'
import Search from './search.js'
const debug = Debug('cot:search:search-field')

/** @typedef {{searchText: string}} Interceptor */

class GlobalSearchController {
  /** @type {Scope} */
  #currentLocalScope = undefined

  /** @type {{global: Set<Search>, local: Set<Search>}} */
  #listeners = {
    global: new Set(),
    local: new Set()
  }

  #propagateActualSearchText = false

  #reactiveData = Vue.observable(
    {
      allowNavigation: true,
      allowScopedSearch: false,
      /** @type {{global: Search, local: Search}} */
      currentListener: {
        global: undefined,
        local: undefined
      },
      /** @type {Search} */
      forcedActiveSearch: undefined,
      /** @type {Interceptor} */
      interceptor: undefined,
      /** @type {Scope} */
      scope: Scope.global(),
      /** @type {ScopeMode} */
      scopeMode: 'global'
    }
  )

  #searchTextToPropagate = ''

  /**
   * @private
   */
  _applyScope () {
    this.activeSearch.setScope(this.scopeMode === this.GLOBAL_MODE ? Scope.global() : this.#currentLocalScope)
    VueInstance.$emit('scope:changed')
  }

  /**
   * @private
   */
  _updateCurrentListeners () {
    const activeSearchs = new Set();

    [this.GLOBAL_MODE, this.LOCAL_MODE].forEach(scope => {
      const search = [...(this.#listeners[scope])].pop()
      activeSearchs.add(search)
      if (this.#reactiveData.currentListener[scope] !== search) {
        this.#reactiveData.currentListener[scope] = search
        debug(`Search ${this.#reactiveData.currentListener[scope].searchId} is listening for scope "${scope}"`)
      }
    })

    activeSearchs.forEach(search => {
      if (search.action === Search.actions.GRID) {
        search.watchMainPreset()
      }
    })

    new Set([...this.#listeners[this.GLOBAL_MODE], ...this.#listeners[this.LOCAL_MODE]])
      .forEach(search => {
        if (!activeSearchs.has(search)) {
          search.unwatchMainPreset()
        }
      })

    this._applyScope()
  }

  emitSendSearchText () {
    VueInstance.$emit('search-text:send')
  }

  /**
   * Force a search to be the active search
   * @param {Search} search
   */
  forceActiveSearch (search) {
    debug(`Search "${search.searchId}" has been forced as the active search`)
    this.#reactiveData.forcedActiveSearch = search
  }

  /**
   * Return a search if it's active, in grid mode, and match scope and selected document type
   * @param {Scope} scope The scope of the search
   * @param {string} documentType The document type that must be selected in the search
   * @returns {Search}
   */
  getActiveGridSearch (scope, documentType) {
    if (
      this.#reactiveData.forcedActiveSearch?.scope.type === scope.type &&
      this.#reactiveData.forcedActiveSearch?.documentType === documentType
    ) {
      return this.#reactiveData.forcedActiveSearch
    }

    for (const scopeMode of [this.GLOBAL_MODE, this.LOCAL_MODE].sort(a => a === this.scopeMode ? -1 : 1)) {
      const search = this.#reactiveData.currentListener[scopeMode]
      if (
        search &&
        search.action === Search.actions.GRID &&
        search.scope?.type === scope.type &&
        search.documentType === documentType
      ) {
        return search
      }
    }
  }

  /**
   * Unregister a callback from "scope:changed" event
   * @param {Function} callback
   */
  offScopeChange (callback) {
    VueInstance.$off('scope:changed', callback)
  }

  /**
   * Unregister a callback from "search-text:sendsearch-text:send" event
   * @param {Function} callback
   */
  offSendSearchText (callback) {
    VueInstance.$off('search-text:send', callback)
  }

  /**
   * Register a callback for "scope:changed" event
   * @param {Function} callback
   */
  onScopeChange (callback) {
    VueInstance.$on('scope:changed', callback)
  }

  /**
   * Register a callback for "search-text:send" event
   * @param {Function} callback
   */
  onSendSearchText (callback) {
    VueInstance.$on('search-text:send', callback)
  }

  /**
   * Propagate the actual search text to the next registered search
   */
  propagateActualSearchText () {
    debug('Search text will be propagated to the next registering search')
    this.#propagateActualSearchText = true
    this.#searchTextToPropagate = this.activeSearch.searchText
  }

  /**
   * Register a search for the given scope modes
   * @param {Search} search
   * @param {Array.<ScopeMode>} scopes
   */
  register (search, scopes) {
    debug(`Search ${search.searchId} registered for scopes: ${scopes}`)
    if (process.env.NODE_ENV === 'development') {
      /** Dev checks will be removed during webpack's bundling */
      if (!scopes.length) {
        throw new Error('Registering a search without any scope. At least "global" or "local" should be specified')
      }
    }

    if (this.#propagateActualSearchText) {
      debug(`Search text "${this.#searchTextToPropagate}" has been propagated`)
      search.searchText = this.#searchTextToPropagate
      this.#searchTextToPropagate = ''
      this.#propagateActualSearchText = false
      search.execute(true)
    }

    scopes.forEach(scope => this.#listeners[scope].add(search))
    this._updateCurrentListeners()
  }

  /**
   * Register an interceptor that will intercept searchText
   * Registering another interceptor will replace the current one
   * @param {Interceptor} interceptor
   */
  registerInterceptor (interceptor) {
    debug('Intereptor registered')
    this.#reactiveData.interceptor = interceptor
  }

  /**
   * Remove the current interceptor
   */
  removeInterceptor () {
    debug('Intereptor released')
    this.#reactiveData.interceptor = undefined
  }

  /**
   * Remove the forced active search
   */
  removeForcedActiveSearch () {
    debug(`Forced active search ${this.#reactiveData.forcedActiveSearch?.searchId} has been removed`)
    this.#reactiveData.forcedActiveSearch = undefined
  }

  /**
   * Allow or deny scoped search
   * @param {boolean} allowed
   * @returns {this}
   */
  setAllowScopedSearch (allowed) {
    this.#reactiveData.allowScopedSearch = allowed
    return this
  }

  /**
   * Define the scope to use when scope mode is set to local
   * @param {Scope} [scope]
   */
  setCurrentLocalScope (scope) {
    this.#currentLocalScope = scope
    return this
  }

  /**
   * Unregister a search from any scope modes
   * @param {Search} search
   */
  unregister (search) {
    search.unwatchMainPreset()
    this.#listeners.global.delete(search)
    this.#listeners.local.delete(search)
    this._updateCurrentListeners()
    debug(`Search ${search.searchId} has been unregistered`)
  }

  get activeSearch () {
    return this.#reactiveData.currentListener[this.scopeMode]
  }

  get allowNavigation () {
    return this.#reactiveData.allowNavigation && VueInstance.$route.name !== 'search'
  }

  set allowNavigation (allowNavigation) {
    this.#reactiveData.allowNavigation = allowNavigation
  }

  get allowScopedSearch () {
    return this.#reactiveData.allowScopedSearch
  }

  get isIntercepted () {
    return !!this.#reactiveData.interceptor
  }

  get scopeMode () {
    return this.#reactiveData.scopeMode
  }

  /**
   * @param {ScopeMode} mode
   */
  set scopeMode (mode) {
    if (this.scopeMode !== mode) {
      debug(`Scope mode changed to ${mode}`)
      this.#reactiveData.scopeMode = mode

      if (this.activeSearch) {
        const actualSearch = this.activeSearch
        if (this.activeSearch !== actualSearch) {
          this.activeSearch.searchText = actualSearch.searchText
        }
      }
    }
    if (this.activeSearch) {
      this._applyScope()
    }
  }

  get text () {
    return this.#reactiveData.interceptor ? this.#reactiveData.interceptor.searchText : this.activeSearch.searchText
  }

  set text (text) {
    if (this.#reactiveData.interceptor) {
      this.#reactiveData.interceptor.searchText = text
    } else {
      this.activeSearch.searchText = text
    }
  }

  /** @typedef {'global'|'local'} ScopeMode */
  /** @type {ScopeMode} */
  GLOBAL_MODE = 'global'
  /** @type {ScopeMode} */
  LOCAL_MODE = 'local'
}

const GlobalSearch = new GlobalSearchController()

VueInstance.$watch(
  '$route',
  route => {
    if (route.name === 'account' && route.params.id) {
      GlobalSearch.setAllowScopedSearch(true)
        .setCurrentLocalScope(Search.typeOfScope.account(route.params.id))
        .scopeMode = GlobalSearch.LOCAL_MODE
    } else {
      GlobalSearch.setAllowScopedSearch(false)
        .setCurrentLocalScope()
        .scopeMode = GlobalSearch.GLOBAL_MODE
    }
  },
  { immediate: true }
)

export default GlobalSearch

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.GlobalSearch = GlobalSearch
}
