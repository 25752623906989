// @ts-check

import Vue from 'vue'

export default class Column {
  #align = 'left'
  /** @type {boolean} */
  #consolidated
  /** @type {string} */
  #currency
  /** @type {string} */
  #id
  /** @type {Object} */
  #options = undefined
  #reactiveData = Vue.observable({
    selectedSummary: undefined
  })

  #sort = () => 0 // This will prevent v-data-table component from sorting rows frontside
  #summary
  /** @type {string} */
  #text
  /** @type {string} */
  #type = undefined
  #value

  /**
   * @param {Object} column
   * @param {string} documentType
   * @param {number|string} identifier
   * @param {Object} additionnalData
   */
  constructor(column, documentType, identifier, additionnalData) {
    this.#consolidated = column.consolidated
    this.#currency = column.currency ?? undefined
    this.#id = column.id
    this.#summary = column.summary
    this.#text = column.name
    this.#value = Number.isInteger(identifier) ? 'c' + identifier : identifier

    /** @type {[string, Object]} */
    const [valueType, valueTypeExtra] = column?.valueType ? Object.entries(column.valueType)[0] : [undefined, undefined]

    this._setTypeAndOptions(documentType, valueType, valueTypeExtra)
    this._setAlign(valueType)

    this.#type = this.#type ? this.#type + '-column' : undefined

    if (column.pin) {
      Object.assign(this, {
        class: ['sticky-column-left'],
        cellClass: ['sticky-column-left']
      })
    }

    Object.assign(this, additionnalData)
  }

  /**
   * @private
   * @param {string} valueType
   */
  _setAlign (valueType) {
    switch (valueType) {
      case 'money':
      case 'balance-slash-amount':
      case 'amt':
      case 'number':
        this.#align = 'right'
        break
      case 'bool':
      case 'button':
      case 'file':
      case 'icon':
      case 'link':
        this.#align = 'center'
        break
    }
  }

  /**
   * @private
   * @param {string} documentType
   * @param {*} valueType
   * @param {*} valueTypeExtra
   */
  _setTypeAndOptions (documentType, valueType, valueTypeExtra) {
    switch (valueType) {
      case 'document':
        this.#type = 'document-ref'
        this.#options = valueTypeExtra
        break
      case 'list':
        this.#type = 'document-ref-list'
        this.#options = {
          hideNavigation: true,
          hideEmail: true,
          hideFullname: false,
          hideGroup: true,
          hidePhone: true,
          offsetY: false
        }
        break
      case 'amt':
      case 'assigned':
      case 'badge-links':
      case 'balance-slash-amount':
      case 'bool':
      case 'label':
      case 'comment':
      case 'create-update-at':
      case 'duration':
      case 'date':
      case 'email':
      case 'file':
      case 'icon':
      case 'id-number':
      case 'percent':
      case 'phone':
      case 'smart-email-sender':
        this.#type = valueType
        this.#options = { documentType }
        break
      case 'decimal':
      case 'integer':
        this.#type = 'number'
        break
      case 'announcement-smart-status':
      case 'collab-dispute-smart-status':
      case 'invoice-smart-status':
      case 'promise-smart-status':
      case 'reminder-smart-status':
      case 'run-smart-status':
        this.#type = 'smart-status'
        this.#options = { documentType }
        break
      case 'string':
        this.#type = 'text'
        break
      case 'translated':
        this.#type = undefined // on ne veux pas que la colonne soit épinglable dans les grilles. (basé sur le type text)
        break
    }
  }

  updateSummary (value) {
    this.#reactiveData.selectedSummary = value
  }

  get align () {
    return this.#align
  }

  get consolidated () {
    return this.#consolidated
  }

  get currency () {
    return this.#currency
  }

  get id () {
    return this.#id
  }

  get options () {
    return this.#options
  }

  get selectedSummary () {
    return this.#reactiveData.selectedSummary
  }

  get sort () {
    return this.#sort
  }

  get summary () {
    return this.#summary
  }

  get type () {
    return this.#type
  }

  get text () {
    return this.#text
  }

  get value () {
    return this.#value
  }
}
